<template>
  <div id="forgotPassword">
    <section class="site-section">
      <div class="container">
        <div class="section-body">
          <div class="row justify-content-center">
            <div class="col-md-5" >
              <div class="form-container">
                <div class="form-header">
                  <h2 class="form-title">
                    שכחתי סיסמה
                  </h2>
                </div>

                <div class="form-body">
                  <div class="form-group">
                    <form action="/" method="post" class="gong-form" @submit.prevent="forgetPassword">
                      <div class="form-group">
                        <input
                          type="password"
                          :class="['form-control', {'is-invalid': passwordErr !== '' || verifyResetPasswordStatus === false}]"
                          placeholder="סיסמה חדשה"
                          v-model.trim="password"
                        >
                        <div class="invalid-feedback" v-if="passwordErr !== ''">
                          {{ passwordErr }}
                        </div>
                      </div>

                      <div class="form-group">
                        <input
                          type="password"
                          placeholder="תאשר סיסמא חדשה"
                          v-model.trim="passwordRepeat"
                          :class="['form-control', {'is-invalid': errorMsg !== '' || verifyResetPasswordStatus === false}]"
                        >
                        <div class="invalid-feedback" v-if="errorMsg !== '' || verifyResetPasswordStatus === false">
                          {{ errorMsg || verifyResetPasswordMsg }}
                        </div>
                      </div>

                      <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block">
                          שמור שינויים
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME+' | שכחתי סיסמה | '+process.env.VUE_APP_NAME_HEB
    }
  },

  data() {
    return {
      password: '',
      passwordRepeat: '',
      passwordErr: '',
      errorMsg: '',
    }
  },

  computed: {
    hash() {
      return this.$route.query.hash
    },
    verifyResetPasswordStatus() {
      return this.$store.getters.verifyResetPasswordStatus
    },
    verifyResetPasswordMsg() {
      return this.$store.getters.verifyResetPasswordMsg
    },
  },

  watch: {
    verifyResetPasswordStatus(newVerifyResetPasswordStatus) {
      if(newVerifyResetPasswordStatus) {
        this.$router.push({name: 'login', params: {fromForgetPassword: true}})
      }
    }
  },

  methods: {
    async forgetPassword() {
      this.errorMsg = ''
      this.passwordErr = ''

      if(this.passwordRepeat !== this.password) {
        this.errorMsg = 'הסיסמאות אינם תואמות'
        return
      }

      if(this.password === '' && this.passwordRepeat === '') {
        this.passwordErr = 'שדה זה הינו חובה'
        return
      }

      const form = new FormData()
      form.append('hash', this.hash)
      form.append('password', this.password)
      form.append('passwordrepeat', this.passwordRepeat)

      await this.$store.dispatch('verifyResetPassword', {form})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

@include media-breakpoint-down(sm) {
  .site-section {
    margin-top: 2.9rem;
  }
  .form-header {
    margin-bottom: 1.5rem;
  }
  .form-title {
    font-size: 1.2rem;
  }
  .gong-form .form-group .form-control {
    height: 3.4rem;
    font-size: .8rem;
  }
  #forgotPassword .gong-form .form-group .btn {
    height: inherit;
    font-size: 1rem;
    line-height: 2;
  }
}
</style>
